import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { PackageMetadata } from "@happeo/docs-tools";
import TranslationProvider from "../../../src/gatsby-theme-docz/TranslationProvider";
import pkg from "../package.json";
import { CopyToClipboardButton } from "../src/index";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "copy-to-clipboard-button"
    }}>{`Copy-to-clipboard-button`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
Button that copies content to the clipboard
    <Playground __position={1} __code={'<TranslationProvider>\n  <div\n    style={{\n      display: \'flex\',\n      justifyContent: \'center\',\n      alignItems: \'center\',\n      flexDirection: \'column\',\n    }}\n  >\n    <div style={{ width: \'50px\' }}>\n      <CopyToClipboardButton\n        clipboardContent=\"some content\"\n        onSaveToClipboard={() => console.log(\'copied stuff\')}\n      />\n    </div>\n  </div>\n</TranslationProvider>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      TranslationProvider,
      pkg,
      CopyToClipboardButton,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <TranslationProvider mdxType="TranslationProvider">
    <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}>
      <div style={{
            width: "50px"
          }}>
        <CopyToClipboardButton clipboardContent="some content" onSaveToClipboard={() => console.log("copied stuff")} mdxType="CopyToClipboardButton" />
      </div>
    </div>
  </TranslationProvider>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      